.refund-alert {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 5px;

    &__edit {
        color: #7d879c;
    }

    &__final {
        margin-left: auto;
        color: red;
    }
}

.refund-error {
    color: red;
}